<template>
  <div class="application-select">
    <v-autocomplete
      :items="tenantApps"
      :value="getSelectedTemplateApp"
      :class="attention"
      label="Filter Application"
      item-value="code"
      item-text="name"
      @change="onChange"
  />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import tenantService from '@/services/TenantService';

export default {
  name: 'TenantApplicationSelect',
  data: () => ({
    attention: 'ap-table-header-item',
    selectedApp: null,
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getSelectedTemplateApp']),
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
    }),
  },
  methods: {
    ...mapActions('tenantApplications', [
      'fetchTenantApps',
    ]),
    ...mapActions('app', ['setSelectedTemplateApp']),
    onChange(appCode) {
      /* Sometimes i'm getting an event here.. */
      if (typeof appCode === 'string') {
        this.attention = 'ap-table-header-item';
        /*  this.selectedApp = appCode; */
        this.setSelectedTemplateApp(appCode);
        this.$emit('onChange', appCode);
      }
    },
    async setComponent() {
      if (this.getStickyTenant) {
        const code = this.getStickyTenant;

        await this.fetchTenantApps({ service: tenantService, key: 'code', tenantCode: code });
        this.$emit('tenantAppReset');
      }
    },
  },
  watch: {
    getStickyTenant() {
      this.setComponent();
    },
    tenantApps(apps) {
      if (apps.length) {
        this.selectedApp = apps[0].code;
        this.onChange(apps[0].code);
      } else {
        this.attention = 'attention ap-table-header-item';
      }
    },
    getSelectedTemplateApp(newVal, oldVal) {
      this.onChange(newVal);
    },
  },
  async mounted() {
    this.setComponent();
  },
};
</script>
